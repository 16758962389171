import { useState } from 'react';
import './App.scss';


function App() {
    const [contact, setContact] = useState({
        name: '',
        hurigana: '',
        email: '',
        phone: '',
        referredDate1: '',
        referredDate2: '',
        referredDate3: '',
        subject: 'お問い合わせ',
        honeypot: '',
        message: '',
        replyTo: '@',
        accessKey: 'cd24ffff-1c9a-4c90-9e97-4a7fcaeff4c8',
    });
    const [isSuccess, setIsSuccess] = useState(false);
    const [response, setResponse] = useState({
        type: '',
        message: '',
    });
    const closeSuccess = () => setIsSuccess(false)
    const handleChange = e => setContact({...contact, [e.target.name]: e.target.value});
    const handleSubmit = async e => {
        e.preventDefault();
        try {
          const res = await fetch('https://api.staticforms.xyz/submit', {
            method: 'POST',
            body: JSON.stringify(contact),
            headers: {'Content-Type': 'application/json'},
          });
    
          const json = await res.json();
    
          if (json.success) {
            //成功したらsuccessページに飛ぶ
            setIsSuccess(true)
          } else {
            setResponse({
              type: 'error',
              message: json.message,
            });
          }
        } catch (e) {
          console.log('An error occurred', e);
          setResponse({
            type: 'error',
            message: 'An error occured while submitting the form',
          });
        }
      };
    return (
        <div className="App">
            {
                isSuccess ? (
                    <div class="success-page">
                        <h1>お問合せありがとうございます。</h1>
                        <p onClick={closeSuccess}>トップに戻る</p>
                    </div>
                ) : (
                    <div>
                    <div className="main">
                        <img src="/image/first-view.jpg" className="first-view-img" alt="first view" />
                        <div className="cover">
                            <h1>腰痛に特化した鍼灸院</h1>
                        </div>
                        <div className="box1">
                            <h1 className="covid-19">当院では新型コロナウイルス感染防止対策を徹底しております。</h1>
                            <div className="covid-19list">
                                <ul>
                                    <li><img src="image/mask.png" alt="コロナ写真１" className="covid-19img" />
                                    </li>
                                    <li><img src="image/ventilation.png" alt="コロナ写真２" className="covid-19img" />
                                    </li>
                                    <li><img src="image/hand-wash-icon.png" alt="コロナ写真３" className="covid-19img" />
                                    </li>
                                    <li><img src="image/thermometer.png" alt="コロナ写真４" className="covid-19img" />
                                    </li>
                                </ul>
                            </div>
                            <div className="covid-19text">
                                <ol>
                                    <li>マスクの着用</li>
                                    <li>院内の換気、適切な湿度の確保</li>
                                    <li>施術ベッド、フェイスマクラ、テーブル、イスなどのアルコール消毒</li>
                                    <li>施術後は毎回手洗い、うがいの徹底</li>
                                    <li>スタッフの体温測定、体調管理など</li>
                                </ol>
                            </div>
                            <p>上記を徹底し患者さまへ安心してご来院いただけるように努めております。</p>
                        </div>
                        <div className="oushin">
                            <h2>往診始めました！</h2>
                            <p>
                                往診を新たに始めたことによって、自分の足で来れない方や、<br />コロナの感染リスクあって外出を控えている方でも安心して診断できる様になりました。
                            </p>
                            <p>鍼灸・マッサージの医師からの同意書を取って頂き、歩行困難の方であれば1回の施術時間30分程度で500円前後での施術が可能です。</p>
                            <p>※ただし直線距離16km圏内に限ります。</p>
                            <a href="/#resevation" className="oushin-btn">往診予約はこちらから</a>
                        </div>
                        <div className="scene1">
                            <h2>あなたは、こんな悩みはありませんか？</h2>
                            <p className="nayami-sub-title">以下の症状が当てはまる方は、「天鍼堂」がお役に立てると思います。</p>
                            <div className="nayami-list0">
                                <ul>
                                    <li>整骨院、病院、マッサ－ジなどいろいろと行ってみたが、少しも良くならない</li>
                                    <li>この痛み、症状は【一生の付き合い、良くならない】と諦めている</li>
                                    <li>痛み止めや湿布で慢性肩こりをごまかすような生活が続いている</li>
                                    <li>病院では【異常なし】と言われ、はっきりとした原因がわからない</li>
                                    <li>痛み、辛さを理解してくれる人がいない</li>
                                    <li>慢性肩こりを忘れて、仕事、プライベ－トも思いっきりやりたい</li>
                                </ul>
                            </div>
                        </div>
                            <div className="nayami">
                            <div className="container">
                                <h1>その悩み当院で解決できます！</h1>
                                <p>当医院では鍼（はり）や灸（きゅう）を使ったマッサージ<br />で、身体のツボを刺激し施術を行います。</p>
                                <p>患者さんが来院したら症状・痛みの具合・部位などを確認して、鍼や灸、マッサージで体調が改善する様な施術を試みます。</p><br/>

                                <div className="nayami-contents">
                                    <h1>このようなお悩みの方が来られています</h1>
                                </div>

                                <div className="nayami-list">
                                    <ul>
                                        <li>神経痛</li>
                                        <li>リウマチ</li>
                                        <li>頚肩腕症候群</li>
                                        <li>頚椎捻挫後遺症</li>
                                        <li>五十肩</li>
                                        <li>腰痛</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="equipment-used container">
                        <div>
                            <h1>使用機材</h1>
                            <div className='equipment-item'>
                                <div className="equipment-item-list">
                                    <p>電気バリ</p>
                                    <img src="/image/kizai4.jpg" alt="kizai" />
                                </div>
                                <div className="equipment-item-list">
                                    <p>セラミック電気温灸器</p>
                                    <img src="/image/kizai1.jpg" alt="kizai" />
                                </div>
                                <div className="equipment-item-list">
                                    <p>コーケントー</p>
                                    <img src="/image/kizai2.jpg" alt="kizai" />
                                </div>
                                <div className="equipment-item-list">
                                    <p>往診用コーケントー</p>
                                    <img src="/image/kizai3.jpg" alt="kizai" />
                                </div>
                            </div>
                            <div className="konkento-about">
                                <p>
                                コーケントーとは
                                光線治療器（カーボンアーク灯治療器）は、太陽光に似た光線を人体に照射することで、<br />赤外線・可視線・わずかな紫外線等の連続スペクトル光線と熱の効果によって日常生活に必要不可欠なエネルギーを補う治療器です。
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="customer-voice">
                        <h2>当院に寄せられた患者さまの声</h2>
                        <div className="cv-list">
                            <div className="cv-contents">
                                <div className ="cv-contents-title1">
                                    <h3>体の事を本当によく分かっている先生なのでこれからも<br />
                                    通院したいです</h3>
                                    <figure>
                                        <img src="image/k様.png" className="cv-img2" />
                                    </figure>
                                </div>

                                <div className="cv-contents2">
                                    <div className="cv-contents-title2">
                                        <p>患者K様</p>
                                    </div>
                                    <p className="cv-contents-sentence">
                                        脊柱管狭窄症と病院で診断され病院でも整骨院でも色々試したけどなかなか良くならず
                                        たまたま当院を見つけて放ってきて良くなったとお声を頂きました。それまでは100メートルぐらい歩くと
                                        休んではまたその繰り返しで買い物行くのも大変だったのですが、一回の施術で以前から腰痛が軽くなったら
                                        汚い換気扇の掃除をしようと思っていて、掃除をやってしまったとの事でした。前もって掃除して大丈夫ですか？聞かれたら
                                        確実にやめておいた方がいいと言った事でしょう。ですが、患者様は一度の施術でこんなにも効果が出るのかと喜ばれました。
                                        その後も悪くならない様に継続して来て下さいました。
                                    </p>
                                    <div className="tensen"></div>
                                    <div className="cv-text3">
                                        <p>※個人の感想であり、成果や成功を保証するものではありません。</p>
                                    </div>
                                </div>
                            </div>
                            <div className="cv-contents">
                                <div className ="cv-contents-title1">
                                    <h3>体の事を本当によく分かっている先生なのでこれからも<br/>
                                    通院したいです</h3>
                                    <figure>
                                        <img src="image/y様.png" className="cv-img2"/>
                                    </figure>
                                </div>

                                <div className="cv-contents2">
                                    <div className="cv-contents-title2">
                                        <p>患者Y様</p>
                                    </div>
                                    <p className="cv-contents-sentence">
                                        精神的に参っている状態の方で腰痛が酷く歩けないほどの方でしたので往診で対応しました。
                                        お話しされるのも辛いぐらいでしたが徐々に色んなお話を伺い徐々に元気になられ腰痛も完璧ではないに
                                        しても外に出掛けていけるほど回復されて喜ばれてました。今も継続して施術を続けておられています。
                                    </p>
                                    <div className="tensen"></div>
                                    <div className="cv-text3">
                                        <p>※個人の感想であり、成果や成功を保証するものではありません。</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pro">
                        <div className="container">
                            <div className="incho-title">
                            <h1>院長プロフィール</h1>
                            <h2>私の想いをお伝えします。</h2>
                            </div>
                            <div className="incho-pro">
                                <div className="sp">
                                    <img src="image/incho-img.jpg" alt="院長"/>
                                </div>
                                <div className="incho-text">
                                    <p>私は鍼灸師の父の元23歳の時からお手伝いをしな<br/>がら鍼灸マッサージの免許を得る為に学校へ<br/>行き始めました。</p>
                                    <p>学校で学ぶ事と実践で得られることは全く別物で<br/>他の同級生よりも良い環境で色々学ぶことが出来<br/>ました。</p>
                                    <p>今やそれが財産だと思っております。</p>
                                    <p>その父の元では13年勤めそれからは完全独立し<br/>て鍼灸マッサージ院を開業しております。</p>
                                    <p>父の元では患者様を診るのと自分自身のみで診る<br/>のではまた違った意味で色々勉強になりました<br/>し自分で何とかしなければと責任感も強くなり<br/>ました。</p>
                                    <p>今や船橋の地で20年が経とうとしています。</p>
                                    <p>これからもこの船橋市本町で頑張って行こうと<br/>思っておりますのでどうぞ宜しくお願い致しま<br/>す。</p>
                                </div>
                                <div className="pc">
                                    <img src="image/incho-img.jpg" alt="院長"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flow">
                        <div className="flow-container">
                            <h2 className="flow-title">当院の施術の流れ</h2>
                            <div className="flow-time">
                                <h2 className="flow-time-title">施術時間について</h2>
                                <p>初回：約90分</p>
                                <p><span>(初回は施術前に痛みの原因を確認するため問診や検査を十分行います)</span></p>
                                <p>2回目以降：約50〜60分</p>
                            </div>
                            <div className="flow-list">
                                <div className="flow-content">
                                    <h2 className="flow-list-title"><span>1.</span>  診断</h2>
                                    <p>全身の状態を拝見し、各種検査法を用いて、症状を引き起こしている原因を突き詰めます</p><br />
                                </div>
                                <div className="flow-content">
                                    <h2 className="flow-list-title"><span>2.</span>  予診表へのご記入</h2>
                                    <p>ご予約時間より10分ほど早くお越しください。</p><br />
                                </div>
                                <div className="flow-content">
                                    <h2 className="flow-list-title"><span>3.</span>  問診</h2>
                                    <p>予診表をもとに、お身体の状態について詳しく伺います。<br />
                                    　・どこが痛むのか、どの姿勢で痛むのか。<br />
                                    　・どんな時間に症状が悪化、軽減するのか。</p>
                                </div>
                                <div className="flow-content">
                                    <h2 className="flow-list-title"><span>4.</span>  視診・触診・脈診（脈状診）</h2>
                                    <p>顔色、皮膚、姿勢、舌、脈、筋肉、ツボ等の状態を拝見して、体質やその日の健康状態を把握します。</p><br />
                                </div>
                                <div className="flow-content">
                                    <h2 className="flow-list-title"><span>5.</span>  施術：当日の体調に合わせた施術内容の決定</h2>
                                    <p>お一人お一人のその日の体調や症状に合わせて、治療内容を決めます。患者様に施術の効果がわかりやすい治療であることを心がけております。</p><br />
                                    施術によって変化するお身体の状態を、ご一緒に確認しながら進めてまいります。<br />
                                </div>
                                <div className="flow-content">
                                    <h2 className="flow-list-title"><span>6.</span>  アドバイス</h2>
                                    <p>お身体に合わせた運動、ストレッチ、食療、日常生活の注意点なども適宣アドバイスさせていただきます。<br />
                                    治療時間と治療回数<br />
                                    　・一回の治療時間は、５０分前後です。<br />
                                    　・治療回数は、症状の重さや、患っていた期間によって違います。<br />
                                    　・痛みがある場合、通常、最初の数回は、間隔を詰めて来院された方が効果的です。<br />
                                    　・症状の改善を観察しながら、徐々に来院の間隔をあけていきます。<br />
                                    治療後のケア<br />
                                    　・鍼灸は、自然治癒力を高めることで症状を改善させていく治療ですので、副作用はありませんが、治療後、まれに、疲労感・眠気・症状が悪化したような気がする等の変化が出る場合があります。これは瞑眩(めんげん)血撃って、効果の現れる証拠とされていますので、ご心配はいりません。その場合はできるだけ安静にしていただきますと、治療効果が高まります。<br />
                                    　・治療したその日は、激しい運動や飲酒は控え、入浴は軽めにして下さい。
                                    　・7時間以上の睡眠を取ることにより一層、自然治癒能力が高まり、治療効果があらわれます。
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="riyu">
                        <div className="container">
                            <div className="incho-title">
                                <h1>よくある質問</h1>
                                <h2>皆様からのよくある質問をまとめさせていただきました。</h2>
                            </div>

                            <div className="riyu-list">
                                <h3 className="title">01. 鍼灸には副作用はあるの？</h3>
                                <div className="heading">
                                    <p>鍼灸はツボを刺激して身体のバランスを整えていく治療法ですので、副作用は殆どありません。子供からお年寄りまで、安心して治療を受けることがきます。</p>
                                </div>
                            </div>

                            <div className="riyu-list ni">
                                <h3 className="title">02. 鍼は痛くないですか？</h3>
                                <div className="heading">
                                    <p>
                                        基本的に鍼は痛くないものです。但し鍼特有の感覚が生ずる場合があります。人によってはそれを痛みと感じる場合がありますが、通常は患部にひびく感じで、なれると心地よさも出てきます。治療に用いる鍼は直径が髪の毛（0.2mm 以下）程度の細さで、先端も裁縫や注射針とは異なり、痛みをできる限り伴わない形状になっています。小児鍼と言って刺さずにツボ刺激をする鍼もございます。
                                    </p>
                                </div>
                            </div>
                            <div className="riyu-list">
                                <h3 className="title">03. 鍼は清潔？消毒されているの？</h3>
                                <div className="heading">
                                    <p>
                                        現在、鍼灸院の大半は“ディスポーザブル( 使い捨て)”鍼を使用しています。消毒滅菌されて梱包されたディスポーザブル鍼は、一回使用ごとに廃棄されます。
                                    </p>
                                </div>
                            </div>
                            <div className="riyu-list ni">
                                <h3 className="title">04. 鍼をしている状態で動いても大丈夫？</h3>
                                <div className="heading">
                                    <p>
                                        皮内鍼であれば、動いたり運動したりしても大丈夫です。皮内鍼は、身体の中には入り込まないようにできている3-5mm 程度の長さの鍼をテープで止めておくタイプのものです。色々な疾病に有効で、肥満対策に使う場合もあります。
                                    </p>
                                </div>
                            </div>
                            <div className="riyu-list">
                                <h3 className="title">05. 妊娠中に鍼灸をしても大丈夫？</h3>
                                <div className="heading">
                                    <p>
                                        鍼灸は母体や胎児への副作用がないため、妊娠中の女性や投薬治療ができない方に適しています。鍼灸によってつわりが緩和されたり、流産癖のある人が無事に出産したり、また、逆子が直ったという例もあります。ただし、妊娠していることは、鍼灸師に治療の前に必ず伝えてください。
                                    </p>
                                </div>
                            </div>
                            <div className="riyu-list ni">
                                <h3 className="title">06. 病院での治療中や投薬中に鍼灸を受けて大丈夫？</h3>
                                <div className="heading">
                                    <p>
                                        疾病によっては、投薬と鍼灸との統合でより高い効果を望める場合もあり、欧米ではそれが標準的な考え方になりつつあります。日本でも、積極的に統合医療を推奨するケースが増えてきました。
                                    </p>
                                </div>
                            </div>
                            <div className="riyu-list">
                                <h3 className="title">07. ストレスや更年期、うつ病などにも効くのですか？</h3>
                                <div className="heading">
                                    <p>
                                        鍼灸はストレスから来る様々な症状の改善に大変有効であるといわれています。また、更年期障害に伴って起こる自律神経失調症や不定愁訴（めまいや動悸、肩こり、腰痛など更年期になって起こりやすい症状）を治めるには、もっとも適している方法のひとつです。うつ病に関しては、心療内科など西洋医学との統合治療がより有効です。
                                    </p>
                                </div>
                            </div>
                            <div className="riyu-list">
                                <h3 className="title">08. 脱毛改善にも効果があるの？</h3>
                                <div className="heading">
                                    <p>
                                        脱毛の原因は様々ですが、ストレスによる円形脱毛の人も多く、その場合はある程度の効果は期待できるでしょう。その他が原因の場合も長期間の治療で効果がみられることもあります。
                                    </p>
                                </div>
                            </div>
                            <div className="riyu-list">
                                <h3 className="title">09. 鍼でタバコをやめられる、というのは本当？</h3>
                                <div className="heading">
                                    <p>
                                        治療を受ければ誰もがやめられるわけではありませんが、効果がある事もございます。
                                    </p>
                                </div>
                            </div>
                            <div className="riyu-list">
                                <h3 className="title">10. 痩せるツボってあるの？</h3>
                                <div className="heading">
                                    <p>
                                        痩せるとして一般的にいわれているのは耳のツボです。耳の胃点、肺点に鍼による通電や皮内鍼という鍼による刺激を加えると、食欲を抑制して体重減少や代謝異常を改善する可能性が推測された、という報告などがあります。しかし、ダイエットを成功させる上で一番大切なことは、正しい生活習慣を継続していくことで、鍼によるツボへの刺激はそのきっかけを作ることです。心身全体の健康のために適切なアドバイスや治療を行うことを心がけています。
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="access">
                        <div className="access-title">
                            <h2>当院までのアクセス</h2>
                            <p>当院はJR船橋駅から10分、京成船橋駅から6分。<br />※駐車場はございませんので、お車でお越しの際はお近くのパーキングエリアに停めてください。</p>
                        </div>
                        <div className="access-main">
                            <div className="map">
                                <p>マップ</p>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.1450903459513!2d139.98669711520094!3d35.698047036657066!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60187fd9c23eeb73%3A0xc9ede441671bb53!2z44CSMjczLTAwMDUg5Y2D6JGJ55yM6Ii55qmL5biC5pys55S677yU5LiB55uu77yT77yQ4oiS77yR77yT!5e0!3m2!1sja!2sjp!4v1657173126688!5m2!1sja!2sjp" width="90%" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                            <div className="access-text">
                                <p>住所：千葉県船橋市本町4-30−13 サンローザヨシノ1階</p>
                                <p>　　　JR船橋駅から10分程度</p>
                                <p>　　　京成船橋駅から6分程度</p>
                                <p>電話番号：047-422-6896(携帯番号：090-6926-3703)</p>
                                <p>受付時間：</p>
                                <p>定休日：日曜日</p>
                                <p>備考：</p>
                            </div>
                        </div>
                    </div>

                    <div className="ryoukin">
                        <h2 className="ryoukin-logo">施術料金について・初回特別割引</h2>
                        <table className="ryoukin-list">
                            <tbody>
                                <tr>
                                    <th>初回施術料</th>
                                    <td>5,000(税込）</td>
                                </tr>
                                <tr>
                                    <th>2回目以降</th>
                                    <td>4,000(税込）</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                    <div className="contact" id="resevation">
                        <div className="container">
                            <div className="contact-a">
                                <h1>往診予約</h1>
                            </div>

                        <div className="contact-1">
                                <h2>電話でのご予約</h2>
                                <p>電話番号:<a href="tel:047-422-6896">047-422-6896</a></p>
                                <p>定休日: 日曜日</p>
                        </div>

                        <div className="contact-2">
                                <h2>往診予約フォーム</h2>
                                <p>----------------------------</p>
                        </div>
                        </div>
                    </div>
                    <from
                        action="https://api.staticforms.xyz/submit"
                        method="post"
                        onSubmit={handleSubmit}
                    >
                        <div className="contact-list">
                            <p>お名前<span>必須</span></p>
                            <input
                                type="text"
                                placeholder="例）山田太郎"
                                name="name"
                                onChange={handleChange}
                                required
                            />
                            <p>ふりがな<span>必須</span></p>
                            <input
                                type="text"
                                placeholder="例）やまだたろう"
                                name="hurigana"
                                onChange={handleChange}
                                required
                            />
                            <p>メールアドレス<span>必須</span></p>
                            <input
                                type="email"
                                placeholder="例）info@example.com"
                                name="email"
                                onChange={handleChange}
                                required
                            />
                            <p>電話番号<span>必須</span></p>
                            <input
                                type="text"
                                placeholder="080-1234-5678"
                                name="phone"
                                onChange={handleChange}
                                required
                            />
                            <p>第一希望日時</p>
                            <input
                                type="text"
                                placeholder="例）1月19日 13:00"
                                name="referredDate1"
                                onChange={handleChange}
                            />
                            <p>第二希望日時</p>
                            <input
                                type="text"
                                placeholder="例）1月19日 14:00"
                                name="referredDate2"
                                onChange={handleChange}
                            />
                            <p>第三希望日時</p>
                            <input
                                type="text"
                                placeholder="例）1月20日 13:00"
                                name="referredDate3"
                                onChange={handleChange}
                            />
                            <div style={{display: 'none'}}>
                                <label className="label">Title</label>
                                <div className="control">
                                <input
                                    type="text"
                                    name="honeypot"
                                    style={{display: 'none'}}
                                    onChange={handleChange}
                                />
                                <input type="hidden" name="subject" onChange={handleChange} />
                                </div>
                            </div>
                            <p>お問い合わせ内容</p>
                            <textarea
                                placeholder="ここにご希望の施術をご記入してください。"
                                name="message"
                                onChange={handleChange}
                                required
                            ></textarea>
                            <div className="form-button">
                                <button onClick={handleSubmit}>送信</button>
                            </div>
                        </div>
                    </from>
                </div>
                )
            }
        </div>
  );
}

export default App;
